import type { MutationTree, GetterTree } from 'vuex'

import { PaymentSteps } from '@/constants/paymentSteps'
import { isSolidForm } from '@/helpers/psp'

import type { RootState } from '../types'
import { FirstPayment } from '../types'

const state: FirstPayment.State = {
  activeStep: PaymentSteps.Select,
  isFirstPaymentLoading: false,
  activeMethod: null,
}

const mutations: MutationTree<FirstPayment.State> = {
  SET_ACTIVE_STEP(
    state: FirstPayment.State,
    data: FirstPayment.Mutations['SET_ACTIVE_STEP'],
  ): void {
    state.activeStep = data
  },
  SET_IS_FIRST_PAYMENT_LOADING(
    state: FirstPayment.State,
    data: FirstPayment.Mutations['SET_IS_FIRST_PAYMENT_LOADING'],
  ): void {
    state.isFirstPaymentLoading = data
  },
  SET_ACTIVE_METHOD(
    state: FirstPayment.State,
    data: FirstPayment.Mutations['SET_ACTIVE_METHOD'],
  ): void {
    state.activeMethod = data
  },
}

const getters: GetterTree<FirstPayment.State, RootState> = {
  activeStep(state: FirstPayment.State): FirstPayment.Getters['activeStep'] {
    return state.activeStep
  },
  isFirstPaymentLoading(state: FirstPayment.State): FirstPayment.Getters['isFirstPaymentLoading'] {
    return state.isFirstPaymentLoading
  },
  activeMethod(state: FirstPayment.State): FirstPayment.Getters['activeMethod'] {
    return state.activeMethod
  },
  isSolidForm(state: FirstPayment.State): FirstPayment.Getters['isSolidForm'] {
    return isSolidForm(state.activeMethod)
  },
  showBigLoader(
    state: FirstPayment.State,
    getters: FirstPayment.Getters,
  ): FirstPayment.Getters['showBigLoader'] {
    return state.isFirstPaymentLoading && getters.isSolidForm
  },
}

export default {
  state,
  mutations,
  getters,
}
