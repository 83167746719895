import { hashString } from '@/helpers/hashString'
import MirrorsService from '@/services/MirrorsService'

export const SOCIAL_NETWORK = [
  941532186, // placetochat
  1380573693, // funchatt
  1543692691, // talkliv
  1418096283, // livebeam
  916060861, // asiatalks
  663119360, // asiavibe
  815515150, // delachat
  1925120319, // latamvibe
  127139812, // wingtalks
  1933389232, // youmetalks
]

export const RONDEVO_SITE = [
  1378707531, // rondevo
]

export const LIVEBEAM_SITE = [
  1418096283, // livebeam
]

export const OLD_GARDEN = [
  MIRROR_VICTORIASTORY,
  MIRROR_VICTORIADATES,
  MIRROR_VALENTIME,
  MIRROR_ROMANCETALE,
  MIRROR_ASIACHARM,
  MIRROR_VICTORIAHEARTS,
  MIRROR_LOVESWANS,
  MIRROR_CHARMERLY,
  MIRROR_AMOURFACTORY,
  MIRROR_DATINGBLOOM,
  MIRROR_JOLLYROMANCE,
  MIRROR_MATCHTRULY,
  MIRROR_LOVEFORT,
  MIRROR_LOVINGFEEL,
  MIRROR_LATINFEELS,
  MIRROR_BRAVODATE,
  MIRROR_UKRAINIANCHARM,
  MIRROR_WISHDATES,
  MIRROR_CUPIDFEEL,
  MIRROR_RONDEVO,
  MIRROR_LOVERWHIRL,
  MIRROR_ASIANFEELS,
  MIRROR_ASIANMELODIES,
  MIRROR_EASTERNHONEYS,
  MIRROR_ORCHIDROMANCE,
  MIRROR_AMOURFEEL,
  MIRROR_VIOLETDATES,
  MIRROR_BESTDATES,
  MIRROR_LA_DATE,
  MIRROR_CALLYOURDATE,
  MIRROR_CHARMINGTALKS,
  MIRROR_CHARMYCHRONICLE,
  MIRROR_CHATSUNITY,
  MIRROR_CHATTINGPLACE,
  MIRROR_CONNECTROMANCE,
  MIRROR_COSMIOCHAT,
  MIRROR_DATEROMANCES,
  MIRROR_DATEWHIRL,
  MIRROR_DATINGARTS,
  MIRROR_DATINGDAZZLE,
  MIRROR_DATINGLAGOON,
  MIRROR_DELIGHTYDATE,
  MIRROR_INSTANTALKS,
  MIRROR_LAUGHLOVES,
  MIRROR_THECHATTLE,
  MIRROR_TRULYROMANCE,
  MIRROR_WISHYCHAT,
  MIRROR_SPARKYDATES,
  MIRROR_VIBEETALK,
]

export const OLD_NEW_GARDEN = [
  MIRROR_PLACETOCHAT,
  MIRROR_FUNCHATT,
  MIRROR_TALKLIV,
  MIRROR_ASIATALKS,
  MIRROR_ASIAVIBE,
  MIRROR_DELACHAT,
  MIRROR_LATAMVIBE,
  MIRROR_WINGTALKS,
  MIRROR_YOUMETALKS,
]

export const NEW_GARDEN = [
  ...OLD_NEW_GARDEN,
  MIRROR_LIVEBEAM,
  MIRROR_INDIMOMENTS,
  MIRROR_ARABCHATS,
  MIRROR_AFRIVIBES,
  MIRROR_PHILITALKS,
  MIRROR_MEETHEAGE,
  MIRROR_CHATSGLOW,
  MIRROR_TALKCHARM,
  MIRROR_CHATSTERRA,
  MIRROR_TALKLYTE,
  MIRROR_CHATGLEAM,
  MIRROR_MEANINGTALKS,
  MIRROR_CHATSRHYTHM,
  MIRROR_TALKSBLISS,
]

export const MIRRORS_FOR_GOOGLE: number[] = [
  MIRROR_CHATTINGPLACE,
  MIRROR_THECHATTLE,
  MIRROR_INSTANTALKS,
  MIRROR_DATEROMANCES,
  MIRROR_DATINGLAGOON,
  MIRROR_CHATSUNITY,
  MIRROR_WISHYCHAT,
  MIRROR_DATINGARTS,
  MIRROR_DATINGDAZZLE,
  MIRROR_LAUGHLOVES,
  MIRROR_CHARMINGTALKS,
  MIRROR_CONNECTROMANCE,
  MIRROR_TRULYROMANCE,
  MIRROR_DELIGHTYDATE,
  MIRROR_CALLYOURDATE,
  MIRROR_CHARMYCHRONICLE,
  MIRROR_COSMIOCHAT,
  MIRROR_VIBEETALK,
  MIRROR_DATEWHIRL,
  MIRROR_SPARKYDATES,
]

export const DESCRIPTOR_NEEDED = [
  MIRROR_VICTORIADATES,
  MIRROR_SAKURADATES,
  MIRROR_LAURADATE,
  MIRROR_AMOREDATE,
  MIRROR_JULIETTDATE,
  MIRROR_EUROMEETS,
  MIRROR_ELENADATE,
  MIRROR_BRAZILIADATES,
  MIRROR_ASIACHARM,
  MIRROR_ASIANFEELS,
  MIRROR_ASIANMELODIES,
  MIRROR_BRAVODATE,
  MIRROR_CHARMERLY,
  MIRROR_CUPIDFEEL,
  MIRROR_DATINGBLOOM,
  MIRROR_EASTERNHONEYS,
  MIRROR_AMOURFACTORY,
  MIRROR_AMOURFEEL,
  MIRROR_LA_DATE,
  MIRROR_LOVERWHIRL,
  MIRROR_LOVESWANS,
  MIRROR_JOLLYROMANCE,
  MIRROR_LATINFEELS,
  MIRROR_LOVEFORT,
  MIRROR_LOVINGFEEL,
  MIRROR_MATCHTRULY,
  MIRROR_ORCHIDROMANCE,
  MIRROR_ROMANCETALE,
  MIRROR_RONDEVO,
  MIRROR_UKRAINIANCHARM,
  MIRROR_VALENTIME,
  MIRROR_VICTORIASTORY,
  MIRROR_VIOLETDATES,
  MIRROR_WISHDATES,
  MIRROR_BESTDATES,
  MIRROR_PHILITALKS,
  MIRROR_DELACHAT,
  MIRROR_LATAMVIBE,
  MIRROR_LATAMVIBE,
  MIRROR_ASIATALKS,
  MIRROR_ASIAVIBE,
  MIRROR_YOUMETALKS,
  MIRROR_MEETHEAGE,
  MIRROR_LIVEBEAM,
  MIRROR_WINGTALKS,
  MIRROR_FUNCHATT,
  MIRROR_VALENTIME,
  MIRROR_SECRETMEET,
  MIRROR_ASIANSOULS,
  MIRROR_GIRLSFROMASIA,
  MIRROR_SAKURALADIES,
  MIRROR_MIAROMANCE,
  MIRROR_AMMOLATINO,
  MIRROR_SLAVICBABES,
  MIRROR_SOPHIADATES,
  MIRROR_GIRLSWITHLOVE,
  MIRROR_MEETWITHMATURE,
  MIRROR_GEISHAMEETS,
  MIRROR_LATINOMARIA,
  MIRROR_AMORELATION,
  MIRROR_UKRAINIANLOVES,
  MIRROR_PHILIPINOSINGLES,
  MIRROR_THAIGIRLDATES,
  MIRROR_KOREADATES,
  MIRROR_CHINA_DATES,
  MIRROR_COLOMBIADATES,
  MIRROR_MEXICANLOVES,
  MIRROR_VENEZUELADATES,
  MIRROR_VICTORIAHEARTS,
  ...MIRRORS_FOR_GOOGLE,
]

export const DESCRIPTOR_NEEDED_MALTA = [
  MIRROR_SAKURADATES,
  MIRROR_LAURADATE,
  MIRROR_AMOREDATE,
  MIRROR_JULIETTDATE,
  MIRROR_EUROMEETS,
  MIRROR_ELENADATE,
  MIRROR_JAPANSDATES,
  MIRROR_BRAZILIADATES,
  MIRROR_GIRLSFROMASIA,
  MIRROR_SECRETMEET,
  MIRROR_ASIANSOULS,
  MIRROR_SAKURALADIES,
  MIRROR_MIAROMANCE,
  MIRROR_AMMOLATINO,
  MIRROR_SLAVICBABES,
  MIRROR_SOPHIADATES,
  MIRROR_GIRLSWITHLOVE,
  MIRROR_MEETWITHMATURE,
  MIRROR_GEISHAMEETS,
  MIRROR_LATINOMARIA,
  MIRROR_AMORELATION,
  MIRROR_UKRAINIANLOVES,
  MIRROR_PHILIPINOSINGLES,
  MIRROR_THAIGIRLDATES,
  MIRROR_KOREADATES,
  MIRROR_CHINA_DATES,
  MIRROR_COLOMBIADATES,
  MIRROR_MEXICANLOVES,
  MIRROR_VENEZUELADATES,
]

export const DESCRIPTORS = {
  [MIRROR_SAKURADATES]: 'chattingspaces.com',
  [MIRROR_LAURADATE]: 'chattingspaces.com',
  [MIRROR_AMOREDATE]: 'chattingspaces.com',
  [MIRROR_JULIETTDATE]: 'chattingspaces.com',
  [MIRROR_EUROMEETS]: 'chattingspaces.com',
  [MIRROR_ELENADATE]: 'chattingspaces.com',
  [MIRROR_JAPANSDATES]: 'chattingspaces.com',
  [MIRROR_BRAZILIADATES]: 'chattingspaces.com',
  [MIRROR_ASIACHARM]: 'billlocator.com',
  [MIRROR_ASIANFEELS]: 'billlocator.com',
  [MIRROR_ASIANMELODIES]: 'billlocator.com',
  [MIRROR_BRAVODATE]: 'billlocator.com',
  [MIRROR_CHARMERLY]: 'billlocator.com',
  [MIRROR_CUPIDFEEL]: 'billlocator.com',
  [MIRROR_DATINGBLOOM]: 'billlocator.com',
  [MIRROR_EASTERNHONEYS]: 'billlocator.com',
  [MIRROR_AMOURFACTORY]: 'billlocator.com',
  [MIRROR_AMOURFEEL]: 'billlocator.com',
  [MIRROR_LA_DATE]: 'billlocator.com',
  [MIRROR_LOVERWHIRL]: 'billlocator.com',
  [MIRROR_LOVESWANS]: 'billlocator.com',
  [MIRROR_JOLLYROMANCE]: 'billlocator.com',
  [MIRROR_LATINFEELS]: 'billlocator.com',
  [MIRROR_LOVEFORT]: 'billlocator.com',
  [MIRROR_LOVINGFEEL]: 'billlocator.com',
  [MIRROR_MATCHTRULY]: 'billlocator.com',
  [MIRROR_ORCHIDROMANCE]: 'billlocator.com',
  [MIRROR_ROMANCETALE]: 'billlocator.com',
  [MIRROR_RONDEVO]: 'billlocator.com',
  [MIRROR_UKRAINIANCHARM]: 'billlocator.com',
  [MIRROR_VICTORIASTORY]: 'billlocator.com',
  [MIRROR_VIOLETDATES]: 'billlocator.com',
  [MIRROR_WISHDATES]: 'billlocator.com',
  [MIRROR_BESTDATES]: 'billlocator.com',
  [MIRROR_PHILITALKS]: 'chtlkbills.com',
  [MIRROR_DELACHAT]: 'chtlkbills.com',
  [MIRROR_LATAMVIBE]: 'chtlkbills.com',
  [MIRROR_ASIATALKS]: 'chtlkbills.com',
  [MIRROR_ASIAVIBE]: 'chtlkbills.com',
  [MIRROR_YOUMETALKS]: 'chtlkbills.com',
  [MIRROR_WINGTALKS]: 'chtlkbills.com',
  [MIRROR_MEETHEAGE]: 'chtlkbills.com',
  [MIRROR_LIVEBEAM]: 'chtlkbills.com',
  [MIRROR_FUNCHATT]: 'funchatt.com',
  [MIRROR_VALENTIME]: 'amourbills.com',
  [MIRROR_SECRETMEET]: 'chattingspaces.com',
  [MIRROR_ASIANSOULS]: 'chattingspaces.com',
  [MIRROR_GIRLSFROMASIA]: 'chattingspaces.com',
  [MIRROR_SAKURALADIES]: 'VISIBLEBILLING.COM',
  [MIRROR_MIAROMANCE]: 'VISIBLEBILLING.COM',
  [MIRROR_AMMOLATINO]: 'chattingspaces.com',
  [MIRROR_SLAVICBABES]: 'chattingspaces.com',
  [MIRROR_SOPHIADATES]: 'chattingspaces.com',
  [MIRROR_GIRLSWITHLOVE]: 'chattingspaces.com',
  [MIRROR_MEETWITHMATURE]: 'chattingspaces.com',
  [MIRROR_GEISHAMEETS]: 'chattingspaces.com',
  [MIRROR_LATINOMARIA]: 'chattingspaces.com',
  [MIRROR_AMORELATION]: 'chattingspaces.com',
  [MIRROR_UKRAINIANLOVES]: 'chattingspaces.com',
  [MIRROR_PHILIPINOSINGLES]: 'chattingspaces.com',
  [MIRROR_THAIGIRLDATES]: 'chattingspaces.com',
  [MIRROR_KOREADATES]: 'chattingspaces.com',
  [MIRROR_CHINA_DATES]: 'chattingspaces.com',
  [MIRROR_COLOMBIADATES]: 'chattingspaces.com',
  [MIRROR_MEXICANLOVES]: 'chattingspaces.com',
  [MIRROR_VENEZUELADATES]: 'chattingspaces.com',
  [MIRROR_VICTORIAHEARTS]: 'billlocator.com',
  [MIRROR_VICTORIADATES]: 'billlocator.com',
  [MIRROR_CHATTINGPLACE]: `${MirrorsService.siteMirror}.com`,
  [MIRROR_THECHATTLE]: `${MirrorsService.siteMirror}.com`,
  [MIRROR_INSTANTALKS]: `${MirrorsService.siteMirror}.com`,
  [MIRROR_DATEROMANCES]: `${MirrorsService.siteMirror}.com`,
  [MIRROR_DATINGLAGOON]: `${MirrorsService.siteMirror}.com`,
  [MIRROR_CHATSUNITY]: `${MirrorsService.siteMirror}.com`,
  [MIRROR_WISHYCHAT]: `${MirrorsService.siteMirror}.com`,
  [MIRROR_DATINGARTS]: `${MirrorsService.siteMirror}.com`,
  [MIRROR_DATINGDAZZLE]: `${MirrorsService.siteMirror}.com`,
  [MIRROR_LAUGHLOVES]: `${MirrorsService.siteMirror}.com`,
  [MIRROR_CHARMINGTALKS]: `${MirrorsService.siteMirror}.com`,
  [MIRROR_CONNECTROMANCE]: `${MirrorsService.siteMirror}.com`,
  [MIRROR_TRULYROMANCE]: `${MirrorsService.siteMirror}.com`,
  [MIRROR_CALLYOURDATE]: `${MirrorsService.siteMirror}.com`,
  [MIRROR_CHARMYCHRONICLE]: `${MirrorsService.siteMirror}.com`,
  [MIRROR_COSMIOCHAT]: `${MirrorsService.siteMirror}.com`,
  [MIRROR_VIBEETALK]: `${MirrorsService.siteMirror}.com`,
  [MIRROR_DATEWHIRL]: `${MirrorsService.siteMirror}.com`,
  [MIRROR_SPARKYDATES]: `${MirrorsService.siteMirror}.com`,
}

export const DESCRIPTORS_MALTA = {
  [MIRROR_SAKURADATES]: 'rapidpaying.com',
  [MIRROR_LAURADATE]: 'day24pay.com',
  [MIRROR_AMOREDATE]: 'securepay24online.com',
  [MIRROR_JULIETTDATE]: '24paysecure.net',
  [MIRROR_EUROMEETS]: 'euromeets.com',
  [MIRROR_ELENADATE]: 'billwaygo.com',
  [MIRROR_JAPANSDATES]: '24paidbill.com',
  [MIRROR_BRAZILIADATES]: 'paywisebilling.com',
  [MIRROR_GIRLSFROMASIA]: 'settle24easy.com',
  [MIRROR_SECRETMEET]: 'swiftsettlepay.com',
  [MIRROR_ASIANSOULS]: '24billpaid.com',
  [MIRROR_SAKURALADIES]: 'service24pay.com',
  [MIRROR_MIAROMANCE]: 'visionpay24.com',
  [MIRROR_AMMOLATINO]: 'paidbillings24.com',
  [MIRROR_SLAVICBABES]: 'easy247billpay.com',
  [MIRROR_SOPHIADATES]: 'rapidbillinghub.com',
  [MIRROR_GIRLSWITHLOVE]: 'authentiserv.com',
  [MIRROR_MEETWITHMATURE]: 'instantsettle24.com',
  [MIRROR_GEISHAMEETS]: 'payserve2.com',
  [MIRROR_LATINOMARIA]: 'billingguard.com',
  [MIRROR_AMORELATION]: 'securepaysmart24.com',
  [MIRROR_UKRAINIANLOVES]: '24payvision.com',
  [MIRROR_PHILIPINOSINGLES]: 'billsets2.com',
  [MIRROR_THAIGIRLDATES]: 'quickpay2u.com',
  [MIRROR_KOREADATES]: 'bill-unit.com',
  [MIRROR_CHINA_DATES]: 'datapaypro.com',
  [MIRROR_COLOMBIADATES]: 'reliablepaywise.com',
  [MIRROR_MEXICANLOVES]: 'authenticpay24.com',
  [MIRROR_VENEZUELADATES]: 'day2daypaying.com',
}

export const NO_LEGAL_DISCLAIMER_FOR_USA = [MIRROR_FUNCHATT]

export const NEW_LEGAL_MIRRORS = [
  MIRROR_CHATSGLOW,
  MIRROR_TALKCHARM,
  MIRROR_CHATSTERRA,
  MIRROR_TALKLYTE,
  MIRROR_CHATSRHYTHM,
  MIRROR_TALKSBLISS,
  MIRROR_CHATGLEAM,
  MIRROR_MEANINGTALKS,
]

export function isOldGarden(mirror: string): boolean {
  return OLD_GARDEN.includes(hashString(mirror))
}

export function isNewGarden(mirror: string): boolean {
  return NEW_GARDEN.includes(hashString(mirror))
}

export function isOldNewGarden(mirror: string): boolean {
  return OLD_NEW_GARDEN.includes(hashString(mirror))
}

export function getDescriptor(mirror: string): string {
  return DESCRIPTORS[hashString(mirror)] ?? ''
}

export function getDescriptorForMalta(mirror: string): string {
  return DESCRIPTORS_MALTA[hashString(mirror)] ?? ''
}

export function isSocialNetwork(mirror: string): boolean {
  return SOCIAL_NETWORK.includes(hashString(mirror))
}

export function isEthnic(mirror: string): boolean {
  return !LIVEBEAM_SITE.includes(hashString(mirror))
}

export function isDescriptorNeeded(mirror: string): boolean {
  return DESCRIPTOR_NEEDED.includes(hashString(mirror))
}

export function isDescriptorNeededForMalta(mirror: string): boolean {
  return DESCRIPTOR_NEEDED_MALTA.includes(hashString(mirror))
}

export function isHiddenPrivacyDisclaimerTextUSA(mirror: string): boolean {
  return NO_LEGAL_DISCLAIMER_FOR_USA.includes(hashString(mirror))
}

export function isNewLegalMirrors(mirror: string): boolean {
  return NEW_LEGAL_MIRRORS.includes(hashString(mirror))
}

export function isGoogleMirror(mirror: string): boolean {
  return MIRRORS_FOR_GOOGLE.includes(hashString(mirror))
}
