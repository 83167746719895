import { LOCAL_STORAGE_KEYS } from '@/constants/localStorageKeys'
import type { Config } from '@/types'

import localStorage from './localStorage'

class ConfigPreparer {
  prepare(config: Config | null): Config | null {
    this.prepareGatewayChanges(config)

    if (!config || !config.packages) {
      return config
    }

    if (!Array.isArray(config.packages)) {
      config.packages = [config.packages]
    }

    config.packages = config.packages.map((x, index) => ({
      index,
      ...x,
    }))

    return config
  }

  private prepareGatewayChanges(config: Config | null): void {
    if (!config) {
      return
    }

    const isGatewayBeingForced = !!config.info.isGatewayBeingForced

    config.info.isGatewayBeingForced = isGatewayBeingForced

    const prevVal = localStorage.get<boolean>(
      LOCAL_STORAGE_KEYS.PaymentChangeGateway,
      isGatewayBeingForced,
    )

    config.info.previousGatewayChanged = prevVal && !isGatewayBeingForced
  }
}

export default new ConfigPreparer()
