import type { GetterTree, MutationTree } from 'vuex'

import type { Plaid, RootState } from '../types'

const state: Plaid.State = {
  accounts: null,
  isConnectAccountLoading: false,
  isTransferLoading: false,
  isTransferProcessing: false,
}

const mutations: MutationTree<Plaid.State> = {
  SET_PLAID_ACCOUNTS(state: Plaid.State, data: Plaid.Mutations['SET_PLAID_ACCOUNTS']): void {
    state.accounts = data
  },
  SET_IS_CONNECT_ACCOUNT_LOADING(
    state: Plaid.State,
    data: Plaid.Mutations['SET_IS_CONNECT_ACCOUNT_LOADING'],
  ): void {
    state.isConnectAccountLoading = data
  },
  SET_IS_TRANSFER_LOADING(
    state: Plaid.State,
    data: Plaid.Mutations['SET_IS_TRANSFER_LOADING'],
  ): void {
    state.isTransferLoading = data
  },
  SET_IS_TRANSFER_PROCESSING(
    state: Plaid.State,
    data: Plaid.Mutations['SET_IS_TRANSFER_PROCESSING'],
  ): void {
    state.isTransferProcessing = data
  },
}

const getters: GetterTree<Plaid.State, RootState> = {
  plaidAccounts(state: Plaid.State): Plaid.Getters['plaidAccounts'] {
    return state.accounts
  },
  isConnectAccountLoading(state: Plaid.State): Plaid.Getters['isConnectAccountLoading'] {
    return state.isConnectAccountLoading
  },
  isTransferLoading(state: Plaid.State): Plaid.Getters['isTransferLoading'] {
    return state.isTransferLoading
  },
  isTransferProcessing(state: Plaid.State): Plaid.Getters['isTransferProcessing'] {
    return state.isTransferProcessing
  },
}

export default {
  state,
  mutations,
  getters,
}
