import { isObject, isUndefined } from 'lodash-es'

import type { ConfigSplits } from '@/types/config'

export function isSplitExists(split: string, splits: ConfigSplits): boolean {
  return isObject(splits) && !isUndefined(splits[split])
}

export function getSplitValue(split: string, splits: ConfigSplits): number {
  return Number(splits[split])
}

export function isInSplit(split: string, splits: ConfigSplits): boolean {
  if (splits && isSplitExists(split, splits)) {
    return Number(getSplitValue(split, splits)) > 0
  }

  return false
}

export function isSplitValue(
  split: string,
  splitValue: number[],
  splits: ConfigSplits | null,
): boolean {
  if (splits && isSplitExists(split, splits)) {
    return splitValue.includes(Number(getSplitValue(split, splits)))
  }

  return false
}
