/* eslint-disable implicit-arrow-linebreak,function-paren-newline */
import configPreparer from '@/helpers/configPreparer'
import { getHashFromUrl } from '@/helpers/getHashFromUrl'
import { convertObjectKeysSnakeToCamel } from '@/helpers/snakeToCamel'
import type { UserInfo, Config, GeoInfo } from '@/types'
import type {
  AutoChargeReq,
  ChangePackage,
  PayByCardPayload,
  PayByCardRequest,
  UpdatePackageReq,
} from '@/types/api'
import { VisitorIdentification } from '@/types/api'
import type { ConfigSplits } from '@/types/config/'
import type { CreditsPackage } from '@/types/config/packages'
import { OriginalPricePackage, OriginalPricePackageResponse } from '@/types/originalPrice'

import Api from './api'

function getHashData(): Object {
  const hash = getHashFromUrl()

  return hash.length ? { hash } : {}
}
export default {
  getConfig(): Promise<Config> {
    return Api.post(`${Api.mirrorEndpoint}/payment/order`, getHashData()).then((resp: any) =>
      configPreparer.prepare(resp.data.config),
    )
  },

  getGeoInfo(): Promise<GeoInfo> {
    return Api.post(`${Api.mirrorEndpoint}/geo/index`, {}).then((resp: any) => resp.data.data)
  },

  autoCharge({ rebill, packageId }: any) {
    const hash = getHashFromUrl()
    const data: AutoChargeReq = {}

    if (hash.length) {
      data.hash = hash
      data.package = packageId
      data.enabled = rebill
    }

    return Api.post(`${Api.mirrorEndpoint}/payment/auto-charge`, data)
  },

  async updatePackage({ packageId }: { packageId: number }): Promise<Config> {
    const hash = getHashFromUrl()
    const data: UpdatePackageReq = { hash, package: packageId }

    const resp = await Api.post<ChangePackage>(`${Api.mirrorEndpoint}/payment/order`, data)

    return configPreparer.prepare(resp.data.config)
  },

  // TODO: rewrite arguments to one object in task plich-46974
  payByCard(payMethod: string, additionalData: PayByCardPayload, idPackage: number | null = null) {
    const { id, paymentType, preselectedPackage } = additionalData

    const data: Partial<PayByCardRequest> = {
      ...getHashData(),
      idToken: id,
      paymentType,
      preselectedPackage,
    }

    if (idPackage !== null) {
      data.package = idPackage
    }

    return Api.post(`${Api.mirrorEndpoint}/payment/${payMethod}`, data)
  },

  getPushId() {
    return Api.post(`${Api.mirrorEndpoint}/social/get-id`).then((resp: any) => resp.data.data)
  },

  getUrl(method: string, data?: Object) {
    const hash = getHashData()

    return Api.post(`${Api.mirrorEndpoint}/payment/url/${method}`, { ...hash, data }).then(
      (resp: any) => resp.data,
    )
  },

  userInfo(): Promise<UserInfo> {
    const data = getHashData()

    return Api.post(`${Api.mirrorEndpoint}/payment/user-info`, data).then((resp: any) => resp.data)
  },

  getVisitorIdentification(): Promise<VisitorIdentification['data']> {
    return Api.post(
      `${Api.mirrorEndpoint}/support/get-visitor-identification`,
      {
        integration: 'zendesk',
      },
      {
        withCredentials: true,
      },
    ).then((resp: any) => resp.data.data)
  },

  getUserSplits(): Promise<ConfigSplits> {
    const data = getHashData()

    return Api.post(`${Api.mirrorEndpoint}/payment/user-splits`, data).then(
      (resp: any) => resp.data,
    )
  },

  async getOriginalPackages(): Promise<CreditsPackage[]> {
    const data = getHashData()

    const resp = await Api.post<OriginalPricePackageResponse>(
      `${Api.mirrorEndpoint}/ecommerce/get-packages/original_price`,
      data,
    )

    const { packages } = resp.data.data

    return packages.map((item) =>
      convertObjectKeysSnakeToCamel<OriginalPricePackage, CreditsPackage>(item),
    )
  },
}
