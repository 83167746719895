export const getHashFromUrl = (): string => {
  const { href } = window.location

  const url = new URL(href)

  if (url.searchParams.has('order')) {
    return url.searchParams.get('order')
  }

  throw new Error('No order hash found')
}
