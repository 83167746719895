import { isObject } from 'lodash-es'

const hasUnderscore = (str: string): boolean => str.includes('_')

const convertSnakeToCamelCase = (str: string): string => {
  if (!hasUnderscore(str)) {
    return str
  }

  const lowerCaseStr = str.toLowerCase()

  const camelCaseStr = lowerCaseStr.replace(/([-_][a-z])/g, (group) => {
    const capitalized = group.toUpperCase()

    return capitalized.replace('-', '').replace('_', '')
  })

  return camelCaseStr
}

const deepSnakeToCamel = <A, R>(obj: A): R => {
  const newObj = {}

  Object.keys(obj).forEach((key) => {
    const camelCaseKey = convertSnakeToCamelCase(key)
    const value = obj[key]

    if (isObject(value)) {
      newObj[camelCaseKey] = deepSnakeToCamel(value)
    } else {
      newObj[camelCaseKey] = value
    }
  })

  return newObj as R
}

export const convertObjectKeysSnakeToCamel = <A, R>(obj: A): R => {
  if (isObject(obj)) {
    return deepSnakeToCamel(obj)
  }

  return {} as R
}
