import Vue from 'vue'
import VueRouter from 'vue-router'

import App from './App.vue'

Vue.use(VueRouter)

export default new VueRouter({
  mode: 'history',
  routes: [
    // TODO: remove the first two routes in plich-xxxxx
    {
      path: '/',
      component: App,
    },
    {
      path: '/popup',
      component: App,
    },
    {
      path: '/static-payment',
      component: App,
    },
  ],
})
