class MirrorsService {
  siteMirror = (() => window.location.hostname.match(/((\w|-)+)/)[0])()

  get defaultOriginMirror(): string {
    return window.location.origin
  }

  hashString(str: string): number {
    let hash = 0
    let chr = 0

    for (let i = 0; i < str.length; i++) {
      chr = str.charCodeAt(i)
      // eslint-disable-next-line no-bitwise
      hash = (hash << 5) - hash + chr
      // eslint-disable-next-line no-bitwise
      hash |= 0
    }

    return Math.abs(hash)
  }
}

export default new MirrorsService()
