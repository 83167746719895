export enum ActionType {
  Click = 'click',
  Close = 'close',
  Scroll = 'scroll',
  Submit = 'submit',
  Resign = 'resign',
  Pay = 'pay',
  Back = 'back',
  Show = 'show',
  On = 'on',
  Off = 'off',
  Enable = 'enable',
  Disable = 'disable',
  MorePackages = 'more_packages',
  AutoRedirect = 'auto_redirect',
  ChoosePackage = 'choose_package',
}

export enum PayPalEventAction {
  ButtonReady = 'button-ready',
  ButtonError = 'button-error',
  OrderStartedProcessing = 'order-started-processing',
  OrderProcessed = 'order-processed',
  OrderAlreadyProcessed = 'order-already-processed',
}

export enum EventType {
  Payments = 'payments',
  Payment = 'payment',
  Profile = 'profile',
  PublicPage = 'publicPage',
  PaymentsLanding = 'payments_landing',
}

export enum PageType {
  Payment = 'payment_page',
}

export const eventSubtypes = {
  Browser: {
    NewTab: 'new_tab',
    Refresh: 'refresh',
    PrevPage: 'prev_page',
  },
  Payments: {
    Order: 'order',
    PopupOneFunnel: 'popup_1_funnel',
    PrePopupOne: 'pre_popup1',
    PopupTwoFunnel: 'popup_2_funnel',
    PopupTwoFirstPage: 'popup_2',
    PopupTwoSecondPage: 'popup_2_2',
    PopupTwoThirdPage: 'popup_2_3',
    Payment: 'payment',
    AdditionalCredit: 'additionalCredit',
    GetCredits: 'get_credits',
    BuyMoreCredits: 'buy_more_credits',
    Benefits: 'benefits',
    SolidFormEvent: 'solid_form_event',
    CorefyFormEvent: 'corefy_form_event',
    PaypalEvent: 'paypal',
    PaypalTab: 'paypal_tab',
    TryAgain: 'try_again',
    PaySuccess: 'pay_success',
    PopupThird: 'popup_3',
    Subscription: 'subscription',
    LetsExplore: 'lets_explore',
    ContactSupport: 'contact_support',
    AutoTopup: 'auto_topup',
    PopupBigPackage: 'popup_big_package',
    PopupBigPackageFunnel: 'popup_big_package_funnel',
    PopupBigPackageTwo: 'popup_big_package_2',
    PopupBigPackageThree: 'popup_big_package_3',
    PopupFastPayment: 'popup_fast_payment',
    PopupFastPaymentFunnel: 'popup_fast_payment_funnel',
    BankTab: 'bank_tab',
    CardTab: 'card_tab',
    CryptoTab: 'crypto_tab',
    ConnectBank: 'connect_bank',
    ChooseBank: 'choose_bank',
    CryptoPaymentBlockedBanner: 'crypto_payment_blocked_banner',
    CryptoPaymentDropdown: 'crypto_payment_dropdown',
    CryptoPaymentBlockedPopup: 'crypto_payment_blocked_popup',
    CryptoWallet: 'crypto_wallet',
    EarlyCryptoWallet: 'early_crypto_wallet',
    ExternalPaymentForm: 'external_payment_form',
    PaymentSuccessful: 'payment_successful',
    PaymentFailed: 'payment_failed',
    PaymentProcessing: 'payment_processing',
    SubscriptionSuccessful: 'subscription_successful',
    SubscriptionFailed: 'subscription_failed',
    SubscriptionProcessing: 'subscription_processing',
  },
  PublicPage: {
    Agree: 'agree',
    Back: 'back',
    BackTopButton: 'backTopButton',
    BackBottomButton: 'backBottomButton',
    Open: 'open',
    Close: 'close',
    View: 'view',
  },
  PageView: {
    PageView: 'page_view',
  },
  Profile: {
    CrmNotifications: 'crm_notifications',
  },
}

export enum EventParamKeys {
  Reason = 'reason',
}

export enum EventParamValues {
  InAppBrowser = 'in_app_browser',
  WaitingForConfirmation = 'waiting_for_confirmation',
  ActiveSession = 'active_session',
}

export interface ITrackingParam<T = any> {
  key: string
  value: T
}

export interface ITrackingEvent {
  type: EventType | 'unknown'
  subtype: string
  action: ActionType | PayPalEventAction
  params: ITrackingParam[]
}

export interface ITrackingSource {
  pageType: PageType
  pageUrl: string
  targetUserId: number
  object: ITrackingObject | null
}

export interface ITrackingObject {
  name: TrackingObjectName
  content?: ITrackingParam[]
}

export enum TrackingObjectName {}
