import { PspMethod, PspMethodTemplateEnum } from '@/types/config/psp'
import { CardType } from '@/types/shared'
import vendorConfig from '@/vendorConfig'

// psp methods that should have "url" as require field
const pspsWithRequiredUrl = ['signedpay', 'sofort', 'ideal', 'corefy']

export function isSolidForm(method) {
  const solidForm = vendorConfig.solid.name

  return solidForm === method?.name
}

export function isCorefyForm(method): boolean {
  return method?.tabText === CardType.Corefy
}

export function isSofortAltSolidActive(method): boolean {
  return method?.template === PspMethodTemplateEnum.SOFORT_SOLID_ALT_CONTAINER
}

export function isIdealAltSolidActive(method): boolean {
  return method?.template === PspMethodTemplateEnum.IDEAL_SOLID_ALT_CONTAINER
}

export function isPaypalAltSolidActive(method): boolean {
  return method?.template === PspMethodTemplateEnum.PAYPAL_SOLID_ALT_CONTAINER
}

export function isPlaid(method: PspMethod): boolean {
  return method.template === PspMethodTemplateEnum.PLAID_CONTAINER
}

export function isCoinsPaid(method: PspMethod): boolean {
  return method?.template === PspMethodTemplateEnum.COINSPAID_CONTAINER
}

export function isVolt(method: PspMethod): boolean {
  return method?.template === PspMethodTemplateEnum.VOLT_SOLID_ALT_CONTAINER
}

export function canBeShown(method: PspMethod): boolean {
  // prettier-ignore
  return (
    method
    && (!pspsWithRequiredUrl.includes(method.name) || method.url !== undefined || isSolidForm(method))
  )
}
