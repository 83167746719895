import { CreditsPackage } from '@/types/config/packages'

export const convertCreditsMessagingIntoGems = (
  creditsMessaging: CreditsPackage['creditsMessaging'],
): number => {
  if (creditsMessaging < 2) {
    return 0
  }

  const gemsCount = Math.floor(creditsMessaging / 2)

  return gemsCount
}
