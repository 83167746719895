import type { CreditsPackage } from '@/types/config/packages'

export type ConfigLayout = {
  template: LayoutTemplate
  autoChargeEnabled?: boolean
  lastPurchasedPackage?: number
  actualPackage?: Omit<CreditsPackage, 'index'>
}

export enum LayoutTemplate {
  ADAPTIVE_MOBILE = 'adaptive-mobile',
  DESKTOP1_POPUP_TABS_NEW_PRICE_3 = 'desktop1-popup-tabs-new-price-3',
  ADAPTIVE1_POPUP_TABS_NEW_PRICE_3 = 'adaptive-popup-mobile-new-price-3',
  DESKTOP1_POPUP_TABS_AUTH = 'desktop1-popup-tabs-auth',
  ADAPTIVE_POPUP_MOBILE_AUTH = 'adaptive-popup-mobile-auth',
  DESKTOP_CREDITS_SUBSCRIPTION_1_POPUP = 'desktop-first-credits-subscription-popup',
  MOBILE_CREDITS_SUBSCRIPTION = 'mobile-first-credits-subscription',
  DESKTOP_CREDITS_SUBSCRIPTION_2 = 'desktop-credits-subscription',
  MOBILE_CREDITS_SUBSCRIPTION_2 = 'mobile-credits-subscription',
  SUBSCRIPTION_POPUP_FIRST = 'subscription-popup-first',
  FAST_MOBILE = 'fast-payment-mobile',
  FAST_DESKTOP = 'fast-payment-desktop',
  DESKTOP_FIRST_POPUP = 'desktop-first-payment-popup',
  FIRST_PAYMENT_LANDING = 'first-payment-landing',
  FIRST_SUBSCRIPTION_LANDING_NEW = 'first-subscription-landing-new',
  FIRST_PAYMENT_ORIGINAL_PACKAGES = 'first-payment-original-packages',
  SECOND_PAYMENT_ORIGINAL_PACKAGES = 'second-payment-original-packages',
  SUBSCRIPTION_FROM_SECOND_PAYMENT = 'subscription-from-second-payment',
}

export enum PackageType {
  CREDITS = 'credits',
  BIG_GIFT = 'big_gift',
}
