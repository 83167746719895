import Vue from 'vue'
import Vuex from 'vuex'
import type { StoreOptions } from 'vuex'

import common from './modules/commonModule'
import firstPayment from './modules/firstPaymentModule'
import paymentStatus from './modules/paymentStatusModule'
import plaid from './modules/plaidModule'
import secondPayment from './modules/secondPaymentModule'
import { RootState } from './types'

Vue.use(Vuex)

const store: StoreOptions<RootState> = {
  modules: {
    secondPayment,
    plaid,
    common,
    firstPayment,
    paymentStatus,
  },
  strict: true,
}

export default new Vuex.Store<RootState>(store)
