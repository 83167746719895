export type CreditsPackage = {
  id: number
  price: string
  origPrice: string
  priceWithTax: string | null
  origPriceWithTax: string | null
  recurringPrice: string | null
  recurringPriceWithTax: string | null
  currency: number
  creditsFree: number
  creditsPremium: number
  creditsMessaging: number
  index: number
  description?: string
  isMostPopular: boolean
  isInitial: boolean
  isBestValue: boolean
  isTax: boolean
  currencySymbol: string
  currencyCode: string
  type: string
  discount: PackageDiscount | null
  groupName?: string
  tag?: string
  recurringPeriodDays?: number
}

export type PackageDiscount = {
  percent: number
  extraCredits: number
  dateExpiration: string | null // YYYY-MM-DD hh:mm:ss
  category: DiscountType
}

export enum DiscountType {
  VIP_DISCOUNT = 'vip_discount',
  DEFAULT = 'default',
  WITHOUT_DEFAULT_PACKAGES = 'without_default_packages',
  CANCEL_SUBSCRIPTION = 'cancel_subscription',
}
