import axios, { AxiosRequestConfig, AxiosResponse } from 'axios'

const endpoint = '/api/frontend'
const apiPayBySolidEndpoint = 'solid-pay-by-token'
const apiPayByCorefyEndpoint = 'corefy-pay-by-token'

const http = axios.create({
  baseURL: '',
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
  withCredentials: true,
})

export default {
  async get<T>(url: string, data?: any, options?: AxiosRequestConfig): Promise<AxiosResponse<T>> {
    return http.get(url, { params: data, ...options })
  },

  async post<T>(url: string, data?: any, options?: AxiosRequestConfig): Promise<AxiosResponse<T>> {
    return http.post(url, data, options)
  },

  async delete<T>(url: string, data: any, options?: AxiosRequestConfig): Promise<AxiosResponse<T>> {
    return http.delete(url, { data, ...options })
  },

  get apiPayBySolidEndpoint() {
    return apiPayBySolidEndpoint
  },

  get apiPayByCorefyEndpoint() {
    return apiPayByCorefyEndpoint
  },

  get endpoint() {
    return endpoint
  },

  get wsEndpoint() {
    return `https://${window.location.host}/push`
  },

  get mirrorEndpoint(): string {
    return `https://${window.location.host}/platform`
  },
}
