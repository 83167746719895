export enum PaymentSteps {
  Status = 'status',
  Pay = 'pay',
  Select = 'select',
  Action = 'action',
  Resign = 'resign',
}

export const PaymentStepsOrder = {
  [PaymentSteps.Select]: {
    prevStep: PaymentSteps.Select,
    index: 0,
  },
  [PaymentSteps.Action]: {
    prevStep: PaymentSteps.Select,
    index: 1,
  },
  [PaymentSteps.Pay]: {
    prevStep: PaymentSteps.Action,
    index: 2,
  },
  [PaymentSteps.Status]: {
    prevStep: PaymentSteps.Pay,
    index: 3,
  },
  [PaymentSteps.Resign]: {
    prevStep: PaymentSteps.Action,
    index: 4,
  },
}

export const SubscriptionPaymentStepsOrder = {
  [PaymentSteps.Action]: {
    prevStep: null,
    index: 0,
  },
  [PaymentSteps.Pay]: {
    prevStep: PaymentSteps.Action,
    index: 1,
  },
  [PaymentSteps.Status]: {
    prevStep: PaymentSteps.Pay,
    index: 2,
  },
  [PaymentSteps.Resign]: {
    prevStep: PaymentSteps.Action,
    index: 3,
  },
}
