export const SPLITS = {
  GET_CR_TOPUP: 'get_cr_topup',
  GET_CR_TOPUP_161: 'get_cr_topup_161',
  THREE_VS_TEN_GOLD: '3vs10_gold',
  FAST_PAYMENT: 'fast_pay',
  FAST_PAYMENT_UPSELL: 'fast_payment_upsell',
  MOBILE_PAY_PAGE_IN_POPUP: 'mobile_pay_page_in_popup',
  CHAT_GEMS: 'chat_gems',
  UI_IMPROVEMENTS_PAYMENT_FLOW: 'ui_improvements_payment_flow',
  FIRST_PAYPAGE_POPUP_DESKTOP_AS_MODAL: 'first_paypage_popup_desktop_as_modal',
  SHOW_METHODS_TAB_IF_MORE_THAN_ONE: 'show_methods_tab_if_more_than_one',
}
