// eslint-disable-next-line max-params
export function getEventData(type, subtype, action, params = null) {
  return {
    type,
    action,
    subtype,
    params,
  }
}

export function getSourceData(pageType, targetUserId, object = null) {
  return {
    pageType,
    object,
    pageUrl: window.location.href,
    targetUserId,
  }
}
