class LocalStorage {
  get<T>(key: string): T | null
  get<T>(key: string, defaultVal: T): T
  get<T>(key: string, defaultVal?: T): T | null {
    const val = localStorage.getItem(key)

    try {
      return JSON.parse(val) ?? defaultVal
    } catch (e) {
      return defaultVal
    }
  }

  set<T>(key: string, value: T): void {
    localStorage.setItem(key, JSON.stringify(value))
  }

  delete(key: string): void {
    localStorage.removeItem(key)
  }
}

export default new LocalStorage()
