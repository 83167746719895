import ErrorTracking from '@socialtechnologies/error-tracking-lib'
import { AxiosError } from 'axios'

import { apiBase } from '@/api'
import { isEthnic, isSocialNetwork } from '@/helpers/mirror'
import PostMessageService from '@/services/PostMessageService'
import { UserEventAction } from '@/services/types'
import vendorConfig from '@/vendorConfig'

export type ZendeskUserInfo = {
  name: string
  id: number | undefined
  email: string
  mirrorId: string
}

class ZendeskService {
  private user: ZendeskUserInfo = {
    name: '',
    email: '',
    mirrorId: '',
    id: undefined,
  }

  get isScriptAvailable(): boolean {
    return typeof window.zE === 'function'
  }

  init(user: ZendeskUserInfo): void {
    this.setUserInfo(user)

    const script = document.createElement('script')
    const src = this.getScriptSrc()

    script.setAttribute('id', 'ze-snippet')
    script.setAttribute('src', src)
    script.onload = this.initWidget.bind(this)
    document.head.appendChild(script)
  }

  openWidget(): void {
    window.zE('webWidget', 'open')
  }

  openMirrorWidget(): void {
    PostMessageService.sendCustomerSupportEvent()
  }

  private setUserInfo(user: ZendeskUserInfo): void {
    this.user = user
  }

  private initWidget(): void {
    window.zE('webWidget', 'setLocale', 'en')
    window.zE('webWidget', 'prefill', {
      name: { value: this.user.name, readOnly: true },
      email: { value: this.user.email, readOnly: true },
    })
    window.zESettings = {
      webWidget: {
        authenticate: {
          chat: {
            jwtFn(callback: any) {
              apiBase
                .getVisitorIdentification()
                .then(({ visitorIdentification }) => {
                  callback(visitorIdentification)
                })
                .catch((e: AxiosError): void => {
                  if (e?.response?.status === 401) {
                    ErrorTracking.captureMessage(
                      'Zendesk get visitor identification 401 unauthorized on platform',
                    )
                  } else {
                    ErrorTracking.captureException(e)
                  }
                })
            },
          },
        },
        contactForm: {
          suppress: true,
          fields: [
            {
              id: vendorConfig.zendesk.fieldId,
              prefill: { '*': this.user.id },
            },
          ],
        },
      },
    }
  }

  private getScriptSrc(): string {
    if (!isEthnic(this.user.mirrorId)) {
      return vendorConfig.zendesk.scriptNotEthnic
    }

    if (isSocialNetwork(this.user.mirrorId)) {
      return vendorConfig.zendesk.scriptNewGarden
    }

    return vendorConfig.zendesk.scriptOldGarden
  }

  updateTitle(title: string): void {
    try {
      window.zE('webWidget', 'updatePath', {
        title,
        url: 'https://example.com/failed',
      })
    } catch (e) {
      ErrorTracking.captureMessage('Zendesk change title issue')
    }
  }

  openZendeskWidgetOutsideIframe(): void {
    if (!this.isScriptAvailable) {
      // setTimeout is required in case when zE script is not evaluated
      setTimeout(() => {
        this.openZendeskWidgetOutsideIframe()
      }, 100)

      return
    }

    window.zE('webWidget:on', 'userEvent', (event: { action: UserEventAction }) => {
      if (event.action === UserEventAction.WidgetOpened) {
        window.zE('webWidget', 'close')
        this.openMirrorWidget()
      }
    })
  }
}

export default new ZendeskService()
