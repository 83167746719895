import { CardType } from '../shared'

export type ConfigPsp = {
  methods: PspMethod[]
}

export type PspMethod = {
  name?: string
  template: PspMethodTemplateEnum
  tokens: PaymentToken[]
  active?: boolean
  tab?: string
  tabText?: CardType
  url?: string
  mode?: string
  merchantData?: SolidMerchantData
  isFrozen?: boolean
}

export enum PspMethodTemplateEnum {
  SIGNEDPAY_IFRAME_CONTAINER_DESKTOP1 = 'signedpay-iframe-container-desktop1',
  PAYPAL_DEFAULT_TAB = 'paypal-default-tab',
  SOFORT_SOLID_ALT_CONTAINER = 'sofort-solid-alt-container', // new
  IDEAL_SOLID_ALT_CONTAINER = 'ideal-solid-alt-container', // new
  PAYPAL_SOLID_ALT_CONTAINER = 'paypal-solid-alt-container', // new
  COREFY_IFRAME_CONTAINER = 'corefy-iframe-container',
  SIGNEDPAY_IFRAME_CONTAINER_MOBILE_POPUP = 'signedpay-iframe-container-mobile-popup',
  PLAID_CONTAINER = 'plaid-container',
  COINSPAID_CONTAINER = 'coins-paid-container',
  VOLT_SOLID_ALT_CONTAINER = 'volt-solid-alt-container',
}

export type PaymentToken = {
  id: number
  card: string
  type: CardType
  countFail: number
  countSuccess: number
  dateFreezeExpiration: string
  isFrozen: boolean
  forcedResign: boolean
  // is returned when forcedResign is true
  resignData?: ResignData
  // client key
  // in seconds from last payment
  elapsed?: number
}

export type ResignData = {
  merchantData: SolidMerchantData
}

export type SolidMerchantData = {
  merchant: string
  signature: string
  paymentIntent: string
}

export type SolidResignRequest = Omit<SolidMerchantData, 'paymentIntent'> & {
  resignIntent: string
}
