export function addGTMScript(code: string): void {
  window.dataLayer = window.dataLayer ?? []

  addGTMScriptTag(code)
  addGTMNoScriptTag(code)
}

function addGTMNoScriptTag(code: string): void {
  const gtmNoscript = document.createElement('noscript')
  const iFrame = document.createElement('iframe')

  iFrame.src = `https://www.googletagmanager.com/ns.html?id=${code}`
  iFrame.width = '0'
  iFrame.height = '0'
  iFrame.style.display = 'none'
  iFrame.style.visibility = 'hidden'

  gtmNoscript.appendChild(iFrame)
  document.body.appendChild(gtmNoscript)
}

function addGTMScriptTag(code: string): void {
  const scripts = document.getElementsByTagName('script')
  const lastScript = scripts[scripts.length - 1]
  const gtmScript = document.createElement('script')
  const gtmContent = document.createTextNode(
    `
    (function (w, d, s, l, i) {
      w[l].push({
        'gtm.start':
          new Date().getTime(), event: 'gtm.js'
      }); var f = d.getElementsByTagName(s)[0],
        j = d.createElement(s), dl = l != 'dataLayer' ? '&l=' + l : ''; j.async = true; j.src =
        'https://www.googletagmanager.com/gtm.js?id=' + i + dl; f.parentNode.insertBefore(j, f);
    })(window, document, 'script', 'dataLayer', '${code}');
    `,
  )

  gtmScript.appendChild(gtmContent)
  lastScript.parentNode.insertBefore(gtmScript, lastScript)
}
