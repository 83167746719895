export function hashString(str: string): number {
  let hash = 0
  let chr = 0

  for (let i = 0; i < str.length; i++) {
    chr = str.charCodeAt(i)
    // eslint-disable-next-line no-bitwise
    hash = (hash << 5) - hash + chr
    // eslint-disable-next-line no-bitwise
    hash |= 0
  }

  return Math.abs(hash)
}
