export default {
  sentryDSN:
    'https://09bf436be2df488997ea4e734989eebc@o4505715306201088.ingest.sentry.io/4505781260189698',
  solid: {
    url: 'https://cdn.charge-auth.com/js/form.js',
    urlOfficial: 'https://cdn.solidgate.com/js/solid-form.js',
    urlFallback: 'https://form-v2.charge-auth.com/js/form.js',
    name: 'signedpay',
  },
  zendesk: {
    scriptNotEthnic:
      '//static.zdassets.com/ekr/snippet.js?key=398fb1bc-57e5-4f0d-ae05-c59b6cfedb48',
    scriptOldGarden:
      '//static.zdassets.com/ekr/snippet.js?key=d76cc45e-05d6-4cb7-b23d-5560135126f3',
    scriptNewGarden:
      '//static.zdassets.com/ekr/snippet.js?key=8e049ee6-1425-4589-a2ab-a41618515370',
    fieldId: 360004252120,
  },
  hotjar: {
    src: 'https://static.hotjar.com/c/hotjar-',
  },
  faq: {
    srcNotEthnic: 'https://support.livebeam.com/hc/en-us',
    srcNewGarden: 'https://support-ng.zendesk.com/hc/en-us',
    srcOldGarden: 'https://support.2-help.io/hc/en-us',
  },
  corefy: {
    url: 'https://cardgate.paycore.io',
  },
  amplitude: {
    API_KEY: '9b748d99d15d5c89dd348cb7916e4e4e',
  },
  plaid: {
    sdkUrl: 'https://cdn.plaid.com/link/v2/stable/link-initialize.js',
  },
  testmoTestcases: 'https://jaybirds.testmo.net/repositories/1',
}
