import ErrorTracking from '@socialtechnologies/error-tracking-lib'
import type { MutationTree, GetterTree, ActionTree, ActionContext } from 'vuex'

import { apiBase } from '@/api'
import PostMessage, { CHANGE_AUTOCHARGE } from '@/dto/post-message'
import { ConfigPackages } from '@/types/config'
import { PackageType } from '@/types/config/layout'

import type { RootState, SecondPayment, Getters } from '../types'

const state: SecondPayment.State = {
  userSelectedPackage: null,
  isAutoTopupEnabledByUser: undefined,
  isAutoTopupChangeSuccessful: false,
  isPaymentStatusLoading: false,
}

const mutations: MutationTree<SecondPayment.State> = {
  SELECT_PACKAGE(
    state: SecondPayment.State,
    data: SecondPayment.Mutations['SELECT_PACKAGE'],
  ): void {
    state.userSelectedPackage = data
  },
  UNSELECT_PACKAGE(state: SecondPayment.State): void {
    state.userSelectedPackage = null
  },
  SET_AUTO_TOPUP(
    state: SecondPayment.State,
    data: SecondPayment.Mutations['SET_AUTO_TOPUP'],
  ): void {
    state.isAutoTopupEnabledByUser = data
  },
  SET_AUTO_TOPUP_CHANGE_SUCCESSFUL(
    state: SecondPayment.State,
    data: SecondPayment.Mutations['SET_AUTO_TOPUP_CHANGE_SUCCESSFUL'],
  ): void {
    state.isAutoTopupChangeSuccessful = data
  },
  SET_IS_PAYMENT_STATUS_LOADING(
    state: SecondPayment.State,
    data: SecondPayment.Mutations['SET_IS_PAYMENT_STATUS_LOADING'],
  ): void {
    state.isPaymentStatusLoading = data
  },
}

const getters: GetterTree<SecondPayment.State, RootState> = {
  // eslint-disable-next-line max-params
  selectedPackage(
    state: SecondPayment.State,
    _getters: SecondPayment.Getters,
    _rootState: RootState,
    rootGetters: Getters,
  ): SecondPayment.Getters['selectedPackage'] {
    if (state.userSelectedPackage) {
      return state.userSelectedPackage
    }

    const actualPackageWithIndexForImage = {
      ...rootGetters.actualPackage,
      index: _rootState.common.config.packages.findIndex(
        (pack) => pack.id === rootGetters.actualPackage.id,
      ),
    }

    return actualPackageWithIndexForImage
  },
  // eslint-disable-next-line max-params
  selectedFastPaymentPackage(
    state: SecondPayment.State,
    _getters: SecondPayment.Getters,
    _rootState: RootState,
    rootGetters: Getters,
  ): SecondPayment.Getters['selectedFastPaymentPackage'] {
    return (
      state.userSelectedPackage ?? rootGetters.actualPackage ?? rootGetters.lastPurchasedPackage
    )
  },
  isAutoTopupInitiallyEnabled(
    _state: SecondPayment.State,
    _getters: SecondPayment.Getters,
    rootState: RootState,
  ): SecondPayment.Getters['isAutoTopupInitiallyEnabled'] {
    return rootState.common.config?.layout?.autoChargeEnabled ?? false
  },
  isAutoTopupEnabled(
    state: SecondPayment.State,
    getters: SecondPayment.Getters,
  ): SecondPayment.Getters['isAutoTopupInitiallyEnabled'] {
    return state.isAutoTopupEnabledByUser ?? getters.isAutoTopupInitiallyEnabled
  },
  autoTopupPrice(
    _state: SecondPayment.State,
    getters: SecondPayment.Getters,
  ): SecondPayment.Getters['autoTopupPrice'] {
    if (!getters.selectedPackage) return ''

    // prettier-ignore
    const isWithDiscountPercent = getters.isSpecialOfferAvailable
      && getters.selectedPackage.discount !== null
      && Boolean(getters.selectedPackage.discount.percent)

    const price = isWithDiscountPercent
      ? getters.selectedPackage.origPrice
      : getters.selectedPackage.price

    return String(Number(price))
  },
  autoTopupActualPrice(
    _state: SecondPayment.State,
    getters: SecondPayment.Getters,
  ): SecondPayment.Getters['autoTopupActualPrice'] {
    if (!getters.selectedPackage) return ''

    const price = getters.selectedPackage.isTax
      ? getters.selectedPackage.priceWithTax
      : getters.autoTopupPrice

    return String(Number(price))
  },

  isSpecialOfferAvailable(
    _state: SecondPayment.State,
    getters: SecondPayment.Getters,
  ): SecondPayment.Getters['isSpecialOfferAvailable'] {
    // prettier-ignore
    return (
      getters.isVipDiscount
      || getters.isDefaultDiscount
      || getters.isWithoutDefaultPackagesDiscount
      || getters.isSubscriptionDeactivationDiscount
    )
  },

  isSpecialOfferAvailableWithPercent(
    _state: SecondPayment.State,
    getters: SecondPayment.Getters,
    rootState: RootState,
  ): SecondPayment.Getters['isSpecialOfferAvailableWithPercent'] {
    const packageWithDiscount = rootState.common.config.packages.find(
      (creditsPackage) => creditsPackage.discount,
    )

    return getters.isSpecialOfferAvailable && Boolean(packageWithDiscount.discount.percent)
  },

  isVipDiscount(
    _state: SecondPayment.State,
    _getters: SecondPayment.Getters,
    rootState: RootState,
  ): SecondPayment.Getters['isVipDiscount'] {
    // prettier-ignore
    const vipPackage = rootState.common.config.packages.find(
      (creditsPackage) => creditsPackage.discount?.category === ConfigPackages.DiscountType.VIP_DISCOUNT,
    )

    return !!vipPackage
  },
  isDefaultDiscount(
    _state: SecondPayment.State,
    _getters: SecondPayment.Getters,
    rootState: RootState,
  ): SecondPayment.Getters['isDefaultDiscount'] {
    const defaultDiscountPackage = rootState.common.config.packages.find(
      (creditsPackage) => creditsPackage.discount?.category === ConfigPackages.DiscountType.DEFAULT,
    )

    return !!defaultDiscountPackage
  },
  isWithoutDefaultPackagesDiscount(
    _state: SecondPayment.State,
    _getters: SecondPayment.Getters,
    rootState: RootState,
  ): SecondPayment.Getters['isWithoutDefaultPackagesDiscount'] {
    // prettier-ignore
    const withoutDefaultPackagesPackage = rootState.common.config.packages.find(
      (creditsPackage) => creditsPackage.discount?.category === ConfigPackages.DiscountType.WITHOUT_DEFAULT_PACKAGES,
    )

    return !!withoutDefaultPackagesPackage
  },
  isSubscriptionDeactivationDiscount(
    _state: SecondPayment.State,
    _getters: SecondPayment.Getters,
    rootState: RootState,
  ): SecondPayment.Getters['isSubscriptionDeactivationDiscount'] {
    // prettier-ignore
    return rootState.common.config.packages.some(
      (creditsPackage) => creditsPackage.discount?.category === ConfigPackages.DiscountType.CANCEL_SUBSCRIPTION,
    )
  },

  arePackagesWithTax(
    _state: SecondPayment.State,
    getters: SecondPayment.Getters,
    rootState: RootState,
  ): SecondPayment.Getters['arePackagesWithTax'] {
    // prettier-ignore
    return (
      rootState.common.config.packages.every((creditsPackage) => creditsPackage.isTax)
      || (getters.isBigPackageAvailable && getters.bigPackage.isTax)
    )
  },

  isBigPackageAvailable(
    _state: SecondPayment.State,
    _getters: SecondPayment.Getters,
    rootState: RootState,
  ): SecondPayment.Getters['isBigPackageAvailable'] {
    return rootState.common.config.layout.actualPackage.type === PackageType.BIG_GIFT
  },
  bigPackage(
    _state: SecondPayment.State,
    _getters: SecondPayment.Getters,
    rootState: RootState,
  ): SecondPayment.Getters['bigPackage'] {
    return rootState.common.config.layout.actualPackage
  },

  isPaymentStatusLoading(
    state: SecondPayment.State,
  ): SecondPayment.Getters['isPaymentStatusLoading'] {
    return state.isPaymentStatusLoading
  },
}

const actions: ActionTree<SecondPayment.State, RootState> = {
  async changeAutoTopup({
    commit,
    getters,
    rootState,
  }: ActionContext<
    SecondPayment.State,
    RootState
  >): SecondPayment.ActionResults['changeAutoTopup'] {
    commit('SET_AUTO_TOPUP_CHANGE_SUCCESSFUL', false)

    if (!getters.selectedPackage || rootState.common.config?.info?.isGatewayBeingForced) {
      return
    }

    commit('SET_AUTO_TOPUP', !getters.isAutoTopupEnabled)

    try {
      await apiBase.autoCharge({
        packageId: getters.selectedPackage.id,
        rebill: getters.isAutoTopupEnabled,
      })

      const message = new PostMessage(CHANGE_AUTOCHARGE, {
        rebill: getters.isAutoTopupEnabled,
      })

      window.parent.postMessage(message, '*')

      commit('SET_AUTO_TOPUP_CHANGE_SUCCESSFUL', true)
    } catch (error) {
      commit('SET_AUTO_TOPUP', !getters.isAutoTopupEnabled)
      commit('SET_AUTO_TOPUP_CHANGE_SUCCESSFUL', false)
      ErrorTracking.captureException(error)
    }
  },
}

export default {
  state,
  mutations,
  getters,
  actions,
}
