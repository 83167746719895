<template>
  <!-- prettier-ignore -->
  <component
    :is="paymentComponent"
    v-if="config && user"
    :user="user"
    :splits="splits"
  />
  <AppLoader v-else />
</template>

<script lang="ts">
import ErrorTracking from '@socialtechnologies/error-tracking-lib'
import { isNil } from 'lodash-es'
import { AsyncComponent } from 'vue'
import { Component, Vue } from 'vue-property-decorator'

import { ENV } from '@/helpers/config'
import { getTemplateFromQueryParams } from '@/helpers/getTemplateFromQueryParams'
import { isLandingLocation } from '@/helpers/isLandingLocation'
import { redirectToCreditsPage } from '@/helpers/redirectToCreditsPage'
import { removeQueryParamsFromLocation } from '@/helpers/removeQueryParamsFromLocation'
import PostMessageService from '@/services/PostMessageService'
import ZendeskService from '@/services/ZendeskService'
import type { Config, GeoInfo, UserInfo } from '@/types'
import type { ConfigSplits } from '@/types/config'
import { LayoutTemplate } from '@/types/config/layout'
import AppLoader from '@/uicomponents/AppLoader.vue'
import vendorConfig from '@/vendorConfig'

import { apiBase } from './api'
import { addAmplitudeScript } from './helpers/addAmplitudeScript'
import { addGTMScript } from './helpers/addGTMScript'
import { ERROR_TRACKING_PROJECT_NAME } from './helpers/consts'
import { isCreditsLocation } from './helpers/isCreditsLocation'
import MirrorPageService from './services/MirrorPageService'
import TrackingService from './services/TrackingService'

@Component({
  components: {
    AppLoader,
  },
})
export default class App extends Vue {
  config: Config | null = null
  geoInfo: GeoInfo | null = null
  user: UserInfo | null = null
  splits: ConfigSplits | null = null

  // eslint-disable-next-line complexity
  get paymentComponent(): AsyncComponent {
    switch (this.$store.getters.template) {
      case LayoutTemplate.ADAPTIVE_MOBILE:
        return () => import('@/pages/FirstPaymentMobile/FirstPaymentMobile.vue')
      case LayoutTemplate.DESKTOP1_POPUP_TABS_NEW_PRICE_3:
        return () => import('@/pages/SecondPaymentDesktop/SecondPaymentDesktop.vue')
      case LayoutTemplate.ADAPTIVE1_POPUP_TABS_NEW_PRICE_3:
        return () => import('@/pages/SecondPaymentMobile/SecondPaymentMobile.vue')
      case LayoutTemplate.DESKTOP1_POPUP_TABS_AUTH:
        return () => import('@/pages/AuthPayment/AuthPaymentDesktop.vue')
      case LayoutTemplate.ADAPTIVE_POPUP_MOBILE_AUTH:
        return () => import('@/pages/AuthPayment/AuthPaymentMobile.vue')
      case LayoutTemplate.DESKTOP_CREDITS_SUBSCRIPTION_1_POPUP:
        // prettier-ignore
        return () => import('@/pages/FirstSubscriptionDesktop/FirstSubscriptionDesktop.vue')
      case LayoutTemplate.MOBILE_CREDITS_SUBSCRIPTION:
        return () => import('@/pages/FirstSubscriptionMobile/FirstSubscriptionMobile.vue')
      case LayoutTemplate.DESKTOP_CREDITS_SUBSCRIPTION_2:
        // prettier-ignore
        return () => import('@/pages/SecondSubscriptionDesktop/SecondSubscriptionDesktop.vue')
      case LayoutTemplate.MOBILE_CREDITS_SUBSCRIPTION_2:
        return () => import('@/pages/SecondSubscriptionMobile/SecondSubscriptionMobile.vue')
      case LayoutTemplate.SUBSCRIPTION_POPUP_FIRST:
        return () => import('@/pages/FirstSubscriptionLanding/FirstSubscriptionLanding.vue')
      case LayoutTemplate.FAST_DESKTOP:
        return () => import('@/pages/FastPayment/FastPaymentDesktop.vue')
      case LayoutTemplate.FAST_MOBILE:
        return () => import('@/pages/FastPayment/FastPaymentMobile.vue')
      case LayoutTemplate.DESKTOP_FIRST_POPUP:
        return () => import('@/pages/FirstPaymentDesktop/FirstPaymentDesktop.vue')
      case LayoutTemplate.FIRST_PAYMENT_LANDING:
        return () => import('@/pages/FirstPaymentLanding/FirstPaymentLanding.vue')
      case LayoutTemplate.FIRST_SUBSCRIPTION_LANDING_NEW:
        return () => import('@/pages/FirstSubscriptionLandingNew/FirstSubscriptionLandingNew.vue')
      case LayoutTemplate.FIRST_PAYMENT_ORIGINAL_PACKAGES:
        return () => import('@/pages/FirstPaymentOriginalPackages/FirstPaymentOriginalPackages.vue')
      case LayoutTemplate.SECOND_PAYMENT_ORIGINAL_PACKAGES:
        // prettier-ignore
        return () => import('@/pages/SecondPaymentOriginalPackages/SecondPaymentOriginalPackages.vue')
      case LayoutTemplate.SUBSCRIPTION_FROM_SECOND_PAYMENT:
        // prettier-ignore
        return () => import('@/pages/SubscripitonFromSecondPayment/SubscriptionFromSecondPayment.vue')
      default:
        ErrorTracking.captureMessage('Wrong layout template')

        return null
    }
  }

  async created(): Promise<void> {
    this.setHeight()
    this.resizeHandler()

    this.initErrorTracking()

    await Promise.all([this.getConfig(), this.getGeoInfo(), this.getUserInfo(), this.getSplits()])

    ErrorTracking.configureScope(String(this.user.idUser), '', (scope) => scope)

    this.setMetaInfo()

    await this.initUserWebTracker()

    this.initGTM()

    if (this.$store.getters.template !== LayoutTemplate.SUBSCRIPTION_POPUP_FIRST) {
      this.initZopimSupport()
    }
    this.initBackUrl()

    PostMessageService.sendWidgetCreatedEvent()

    this.trackPaymentFormShow()
  }

  async getConfig(): Promise<void> {
    try {
      this.config = await apiBase.getConfig()
      this.$store.commit('SET_CONFIG', this.config)

      const templateFromFastPayment = getTemplateFromQueryParams()

      if (templateFromFastPayment) {
        removeQueryParamsFromLocation('template')
      }

      const template = templateFromFastPayment ?? this.config.layout.template

      this.$store.commit('SET_TEMPLATE', template)
    } catch (error) {
      ErrorTracking.captureMessage('Fetch order by start payments', {
        extra: { ...error, href: window.location.href },
      })

      if (!isLandingLocation() && !isCreditsLocation()) {
        redirectToCreditsPage()
      }
    }
  }

  async getGeoInfo(): Promise<void> {
    try {
      this.geoInfo = await apiBase.getGeoInfo()
      this.$store.commit('SET_GEO_INFO', this.geoInfo)
    } catch (error) {
      ErrorTracking.captureException(error)
    }
  }

  async getUserInfo(): Promise<void> {
    try {
      this.user = await apiBase.userInfo()
      this.$store.commit('SET_USER', this.user)
    } catch (error) {
      ErrorTracking.captureException(error)
    }
  }

  async getSplits(): Promise<void> {
    try {
      this.splits = await apiBase.getUserSplits()
      this.$store.commit('SET_SPLITS', this.splits)
    } catch (error) {
      ErrorTracking.captureException(error)
    }
  }

  initGTM(): void {
    if (!isNil(this.config?.marketing?.gtm)) {
      try {
        addGTMScript(this.config.marketing.gtm)
      } catch (e) {
        ErrorTracking.captureMessage('GTM script error', e)
      }
    }
  }

  // Amplitude service is turned off at the moment, but this method is here because it could be activated at any time
  initAmplitude(): void {
    if (!isNil(this.user?.idUser)) {
      try {
        addAmplitudeScript(this.user.idUser)
      } catch (e) {
        ErrorTracking.captureMessage('Amplitude script error', e)
      }
    }
  }

  async initUserWebTracker(): Promise<void> {
    if (this.config && this.user) {
      await TrackingService.init()
    }
  }

  initZopimSupport(): void {
    if (this.config) {
      const { email, firstName, mirrorInfo } = this.config.common
      const zendeskUserInfo = {
        id: this.user?.idUser,
        name: firstName,
        mirrorId: mirrorInfo.abbreviation,
        email,
      }

      ZendeskService.init(zendeskUserInfo)
    }
  }

  initErrorTracking(): void {
    if (!ENV.LOCAL) {
      ErrorTracking.init({
        // @ts-expect-error
        project: ERROR_TRACKING_PROJECT_NAME,
        dsn: vendorConfig.sentryDSN,
        /// vite constant
        releaseName: RELEASE_NAME,
        environment: ENV.PROD ? 'PROD' : 'DEV',
        ignoreErrors: [
          'Network Error',
          'Zendesk get visitor identification 401 unauthorized on platform',
        ],
        replaysOnErrorSampleRate: 0.05,
        tracesSampleRate: 0.02,
        Vue,
        beforeSend: (event) => event,
      })

      ErrorTracking.activateTracking()
      ErrorTracking.activateReply({
        networkDetailAllowUrls: [window.location.origin, 'https://cdn.charge-auth.com'],
      })
      ErrorTracking.activateTraces()
    }
  }

  setMetaInfo(): void {
    if (this.config) {
      const { set } = this.$meta().addApp('App')

      set({
        title: this.config.common.mirrorInfo.name,
        link: [
          {
            rel: 'icon',
            href: `${MirrorPageService.originMirror}/favicon.ico`,
          },
        ],
      })
    }
  }

  initBackUrl(): void {
    const backUrl = this.config?.common?.goBackUrl

    if (backUrl) {
      MirrorPageService.setBackUrl(backUrl)
    }
  }

  trackPaymentFormShow(): void {
    if (LayoutTemplate.SUBSCRIPTION_POPUP_FIRST === this.$store.getters.template) {
      TrackingService.showPaymentForm([
        { key: 'source_type', value: 'external' },
        { key: 'source_subtype', value: 'marketing_land' },
      ])

      return
    }

    if (LayoutTemplate.FIRST_PAYMENT_LANDING === this.$store.getters.template) {
      return
    }

    TrackingService.showPaymentForm()
  }

  beforeDestroy(): void {
    window.removeEventListener('resize', this.resizeHandler)
  }

  setHeight(): void {
    window.addEventListener('resize', this.resizeHandler)
  }

  resizeHandler(): void {
    document.documentElement.style.setProperty('--vh', `${window.innerHeight * 0.01}px`)
  }
}
</script>

<style lang="scss">
@import '@socialtechnologies/ui-components/lib/assets/base.css';
@import '@socialtechnologies/ui-components/lib/style.css';
@import '@socialtechnologies/ui-components/lib/assets/themes/main.css';
@import '@socialtechnologies/ui-components/lib/assets/utils/display.css';
@import '@socialtechnologies/ui-components/lib/assets/utils/spacing.css';
@import '@socialtechnologies/ui-components/lib/assets/utils/typography.css';
@import '@socialtechnologies/ui-components/lib/assets/utils/grid.css';
@import '@/assets/fonts.css';

body {
  background-color: transparent;
}

.zEWidget-launcher--active {
  display: none !important;
}

.zEWidget-launcher,
#launcher {
  top: -200000000 !important;
  display: none !important;
}

@media (max-width: 715px) {
  .contact {
    display: none;
  }
}
</style>
